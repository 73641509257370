.intro {
  background-color: transparent;
}

/* Header.css */
.col-md-8.col-md-offset-2.intro-text {
  padding-top: 100;
}

.centered-image {
  animation: entradaImagem 0.5s ease-in-out;
}

@keyframes entradaImagem {
  0% {
      transform: scale(3); /* Aumenta a escala para 2 */
      opacity: 0;
  }

  100% {
      transform: scale(1); /* Retorna à escala normal de 1 */
      opacity: 1;
  }
}

.animated-image {
  position: relative;
}

.animated-image::before {
  content: "";
  position: absolute;
  top: 50PX;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff00ff; /* Cor da aura */
  opacity: 0.5; /* Opacidade da aura */
  z-index: -1;
  animation: aura-animation 2s infinite; /* Duração e repetição da animação */
}

@keyframes aura-animation {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.2);
  }
  100% {
      transform: scale(1);
  }
}

